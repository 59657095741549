<template>
  <div class="container">
    <iframe class="iframe" :src="exchange_link"></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      exchange_link:'',
    };
  },
  created(){
    const { exchange_link = ""} = this.$route.query;
    this.exchange_link = exchange_link;
    console.log(this.exchange_link)
  },
  mounted(){
    const that = this;
    that.$toast.loading({
      duration: 0,
      forbidClick: true,
      mask: true,
      message: "加载中...",
    });
    const iframe = document.querySelector('.iframe');
        //attachEvent: 处理兼容性问题
        if (iframe.attachEvent) {//兼容IE
            iframe.attachEvent("onload", function () {
                // 加载完成
                that.$toast.clear();
            });
        } else {
            iframe.onload = function () {
                // 加载完成
                that.$toast.clear();
            };
        }
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .iframe{
    height: 100%;
    width: 100%;
  }
}
</style>